<template>
  <section id="dashboard">
    <div v-if="userData.roles[0].id == 1">
      <superAdmin :userData="userData" :dataDashboard="dataDashboard" @buscar="buscar($event)" />
    </div>
    <div v-if="userData.roles[0].id == 2">
      <admin :userData="userData" :dataDashboard="dataDashboard" @buscar="buscar($event)" />
    </div>
    <div v-if="userData.roles[0].id == 3">
      <oma :userData="userData" :dataDashboard="dataDashboard" @buscar="buscar($event)" />
    </div>
    <div v-if="userData.roles[0].id == 4">
      <nurse :userData="userData" :dataDashboard="dataDashboard" @buscar="buscar($event)" />
    </div>
    <div v-if="userData.roles[0].id == 5">
      <frontdesk :userData="userData" :dataDashboard="dataDashboard" @buscar="buscar($event)" />
    </div>
  </section>
</template>

<script>
import axios from "@/core/services/api/dashboard";
import superAdmin from "./superAdmin/superAdmin.vue";
import admin from "./admin/admin.vue";
import frontdesk from "./frontdesk/frontdesk.vue";
import nurse from "./nurse/nurse.vue";
import oma from "./oma/oma.vue";

export default {
  components: {
    admin,
    frontdesk,
    nurse,
    oma,
    superAdmin,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      dataDashboard: {},
    };
  },
  created() {
    if (this.userData.roles[0].id != 2) this.buscar({ start: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`, end: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` });
  },
  methods: {
    buscar({ start, end, facilities }) {
      const datarda = {
        start_date: start,
        end_date: end,
        facilities
      };
      if (this.userData.roles[0].id != 1 && this.userData.roles[0].id != 2) datarda.user_id = this.userData.id
      axios.getData(datarda).then(({ data }) => {
        this.dataDashboard = data;
      });
    },
  },
};
</script>
