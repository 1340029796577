<template>
  <b-card>
    <b-row>
      <b-col>
        <b-row align-h="between">
          <b-col cols="auto">
            <b-row>
              <b-col cols="auto">
                <b-avatar variant="primary" size="40" class="shadow mb-2" v-b-tooltip.hover.top="description">
                  <i class="flaticon-questions-circular-button text-white cursor-pointer icon-2x" />
                </b-avatar>
              </b-col>
              <b-col align-self="center" cols="auto">
                <h1>Average payments</h1>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="auto">
            <b-dropdown right text="Filters" variant="primary">
              <b-dropdown-form>
                <b-form-checkbox-group v-model="selected" v-for="({ title }, key) in averages" :key="key">
                  <b-form-checkbox :value="title">{{ title }}</b-form-checkbox>
                </b-form-checkbox-group>
              </b-dropdown-form>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="9">
        <vue-apex-charts v-if="typeChart == 1" :options="chartOptions" :series="series" height="450" type="line" />
        <vue-apex-charts v-if="typeChart == 2" :options="barChartOptions" :series="series" type="bar" />
      </b-col>
      <b-col cols="3">
        <b-row v-for="({ label, value }, key) in avgs" :key="key">
          <vue-apex-charts :options="radialBarOptions(label, value)" :series="[percentage(value)]" type="radialBar" />
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BFormCheckboxGroup, BDropdownForm, BFormCheckbox, BCard, BAvatar } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    BFormCheckboxGroup,
    VueApexCharts,
    BCard,
    BAvatar,
    BDropdownForm,
    BFormCheckbox
  },
  props: {
    averages: {
      type: Array,
      default: () => [],
      required: true
    },
    dates: {
      type: Object,
      default: () => { },
      required: true
    }
  },
  watch: {
    averages() {
      this.filterData()
    },
    selected() {
      this.filterData(true)
    }
  },
  methods: {
    radialBarOptions(label, value) {
      let color;
      const percentagex = this.percentage(value)
      if (percentagex >= 1 && percentagex <= 30) color = '#FF0000';
      if (percentagex >= 31 && percentagex <= 60) color = '#FFFF00'
      if (percentagex >= 61 && percentagex <= 80) color = '#FFFFCC'
      if (percentagex > 80) color = '#00FF00';
      return {
        chart: {
          // height: 350,
          type: 'radialBar',
          toolbar: {
            show: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '70%',
              background: '#fff',
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: '#fff',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },
            dataLabels: {
              show: true,
              name: {
                show: true,
                color: '#888',
                fontSize: '12.5px'
              },
              value: {
                formatter: function (val) {
                  return value;
                },
                // offsetY: -1,
                color: '#111',
                fontSize: '19px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [color], // Usar el color calculado
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
            colorStops: [
              {
                offset: 0,
                color: color, // Usar el mismo color calculado
                opacity: 1
              },
              {
                offset: 100,
                color: color, // Usar el mismo color calculado
                opacity: 1
              }
            ]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: [label],
      }
    },
    percentage(valor) {
      if (valor < 0) return 0;
      if (valor > 500) return 100;
      return (valor / 500) * 100; // Calcula el porcentaje en función del valor y 500.
    },
    filterData(selected = false) {
      this.typeChart = this.verifyDates(this.dates) ? 2 : 1
      const series = []
      this.avgs = []
      this.averages.forEach(({ title, averages, label }) => {
        if (selected && this.selected.length && !this.selected.includes(title)) return;
        this.avgs.push({ label, value: title.split('$')[1] })
        const data = []
        averages.forEach(({ day, month, year, ...average }) => {
          const y = average[label] != null ? parseInt(average[label]) : 0
          const x = `${month}-${day}-${year}`
          data.push({ x, y })
        })
        series.push({ name: label, data })
      })
      this.series = series
    },
    verifyDates({ start, end }) {
      const fecha1 = new Date(start)
      const fecha2 = new Date(end)
      // Calcula la diferencia en milisegundos
      const diferenciaMs = Math.abs(fecha2 - fecha1);

      // Convierte la diferencia de milisegundos a días
      const diferenciaDias = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24));

      // Verifica si la diferencia es igual o menor a 1 día
      return diferenciaDias <= 1;
    }
  },
  data() {
    return {
      /*
        1: line
        2: bar
      */
      typeChart: 1,
      avgs: [],
      series: [],
      selected: [],
      description: "Averages diarios del periodo de la fecha selecionada",
      chartOptions: {
        xaxis: {
          labels: {
            // show: false,
          }
        },
        chart: {
          type: 'area',
          // height: 350
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          show: true,
          width: 1,
        },
      },
      barChartOptions: {
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "%";
            }
          }

        },
      },
      seriesRadial: [75],
    }
  }
};
</script>