<template>
    <section>
        <div class="grid-container">
            <div class="grid-item">
                <welcome :userData="userData" />
                <filtro class="mt-3" @buscar="buscar($event)" :advanced="true" />
                <totalPatiens class="mt-3" :userData="userData" :dataDashboard="dataDashboard" :fechas="fechas" />
                <averageClinic class="mt-3" :userData="userData" :dataDashboard="dataDashboard" />
            </div>
            <div class="grid-item">
                <statistics :stadistics="stadistics" />
                <averages class="mt-3" :averages="averages" :dates="fechas" />
                <statisticsPatients class="mt-3" :userData="userData" :dataDashboard="dataDashboard" />
            </div>
        </div>
    </section>
</template>

<script>
import { BRow, BCol, BCardGroup } from 'bootstrap-vue'
import welcome from '../components/welcome.vue';
import filtro from '../components/filter';
import averageClinic from '../components/averageClinic';
import statisticsPatients from '../components/statisticsPatients';
import statistics from '../components/statistics';
import totalPatiens from '../components/totalPatiens';
import averages from "../components/averages"

export default {
    components: {
        averages,
        BCardGroup,
        BRow,
        BCol,
        welcome,
        filtro,
        averageClinic,
        statisticsPatients,
        statistics,
        totalPatiens
    },
    props: {
        userData: {
            type: Object,
            default: () => { },
            required: false
        },
        dataDashboard: {
            type: Object,
            default: () => { },
            required: false
        }
    },
    data() {
        return {
            stadistics: [],
            averages: [],
            fechas: {
                start: '',
                end: ''
            }
        }
    },
    watch: {
        dataDashboard() {
            this.stadistics = [
                {
                    description: "Average diario del periodo de la fecha selecionada divido en los dias de ese periodo",
                    value: parseInt(this.dataDashboard.average.income_days),
                    title: "Daily Average Income"
                },
                {
                    description: "Average diario del periodo de la fecha selecionada divido en los dias de ese periodo",
                    value: parseInt(this.dataDashboard.average.expenses_days),
                    title: "Daily Average Expenses"
                },
                {
                    description: "(Daily Average Income) - (Daily Average Expences) - (Devolution)",
                    value: parseInt(this.dataDashboard.average.net_income_days),
                    title: "Net Average Income"
                },
                {
                    description: "Suma total del periodo de la fecha selecionada",
                    value: parseInt(this.dataDashboard.totals.income),
                    title: "Total Income"
                },
                {
                    description: "Suma total  del periodo de la fecha selecionada",
                    value: parseInt(this.dataDashboard.totals.expenses),
                    title: "Total Expenses"
                },
                {
                    description: "(Total Income ) - (Total Expences) - (Devolution)",
                    value: parseInt(this.dataDashboard.totals.net_income),
                    title: "Total net income"
                },
                {
                    description: "Suma total  del periodo de la fecha selecionada En Cash",
                    value: parseInt(this.dataDashboard.cash_cc.cash),
                    title: "Total income cash"
                },
                {
                    description: "Suma total  del periodo de la fecha selecionada en CC",
                    value: parseInt(this.dataDashboard.cash_cc.cc),
                    title: "Total Income CC"
                },
                {
                    description: "Suma total de los montos devueltos por cualquier motivo del periodo de la fecha selecionadaSuma total de los montos devueltos por cualquier motivo del periodo de la fecha selecionada",
                    value: parseInt(this.dataDashboard.total_refunds),
                    title: "Total Devolution"
                },
            ]
            this.averages = [
                {
                    label: 'AVG_TOTAL',
                    title: `Average total $${this.dataDashboard.average.total}`,
                    averages: this.dataDashboard.average.total_daily
                },
                {
                    label: 'AVG_NC',
                    title: `Average NC $${this.dataDashboard.average.nc}`,
                    averages: this.dataDashboard.average.nc_daily
                },
                {
                    label: 'AVG_NP',
                    title: `Average NP $${this.dataDashboard.average.np}`,
                    averages: this.dataDashboard.average.np_daily
                },
            ]
        }
    },
    methods: {
        buscar(filtro) {
            this.fechas = {
                start: filtro.start,
                end: filtro.end
            }
            this.$emit('buscar', filtro)
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/cssDashboards.css';
</style>
