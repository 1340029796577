<template>
  <b-card>
    <h1>Membership Distribution - {{ total }}</h1>
    <vue-apex-charts :options="chartOptions" :series="series" type="pie" />
  </b-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { BCard } from "bootstrap-vue";

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    memberships: {
      type: Object,
      required: true,
      default: () => ({ NP: 0, NC: 0 }),
    },
  },
  data() {
    return {
      chartOptions: {
        tooltip: {
          theme: "custom", // Usamos un tema personalizado para el Tooltip
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#3C231D'],
          },
          dropShadow: {
            enabled: false,
          }
        },
        labels: ["NP: 0", "NC: 0"],
        colors: ["#fbd5cc", "#ffc699"],
      },
      series: [0, 0],
      total: 0,
    };
  },
  watch: {
    memberships: {
      immediate: true,
      handler(newMemberships) {
        this.updateChartData(newMemberships);
      },
    },
  },
  methods: {
  updateChartData(memberships) {
    this.total = memberships.NP + memberships.NC;

    this.chartOptions = {
      ...this.chartOptions,
      labels: [`NP: ${memberships.NP}`, `NC: ${memberships.NC}`],
    };

    this.series = [memberships.NP, memberships.NC];
  },
}
};
</script>