<template>
  <b-card>
    <h1>Total patients</h1>
    <vue-apex-charts :options="chartOptions" :series="series" type="bar" />
    <mixn ref="mixin" />
  </b-card>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import mixn from "@/components/mixn.vue";

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    VueApexCharts,
    mixn
  },
  props: {
    userData: {
      type: Object,
      default: () => { },
    },
    dataDashboard: {
      type: Object,
      required: true,
      default: () => { },
    },
    fechas: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  data() {
    return {
      chartOptions: {
        tooltip: {
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (val, opt) {
                return ''
              }
            }
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
            columnWidth: '50%', // Ajusta este valor para controlar el ancho de las barras
          },
        },
        dataLabels: {
          offsetY: -25,
          style: {
            colors: ['#333'],
            fontSize: '16px', // Ajusta el tamaño de la letra aquí
          }
        }
      },
      series: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "Octuber",
        "November",
        "December",
      ],
    };
  },
  watch: {
    dataDashboard() {
      const { start, end } = this.fechas;

      if (!start && !end) return this.series = this.getDataTable({ property: "patients_per_month" });

      const oneDayMilliseconds = 24 * 60 * 60 * 1000;
      const startDateObject = new Date(start);
      const endDateObject = new Date(end);

      const timeDifference = endDateObject - startDateObject;
      const daysDifference = Math.round(timeDifference / oneDayMilliseconds);

      this.series = daysDifference > 31
        ? this.getDataTable({ property: "patients_per_month" })
        : this.getDataTable({ property: "patients_per_day" });
    },
  },
  methods: {
    getDataTable({ property = "patients_per_month" }) {
      let meses = [];
      const colores = this.$refs.mixin.listaColores();

      this.dataDashboard[property].forEach((entry, indx) => {
        const indexMonth = parseInt(entry.month) - 1
        const colorDef = colores[indx] || this.$refs.mixin.colorGenerator();
        const dataEntry = property === "patients_per_day"
          ? { x: entry.day, y: entry.count, fillColor: colorDef }
          : { x: `${this.months[indexMonth]} - ${entry.year}`, y: entry.count, fillColor: colorDef };
        const yearEntry = meses.find((mes) => mes == dataEntry);

        if (!yearEntry) return meses.push(dataEntry);

        const monthEntry = yearEntry.find((mes) => mes == dataEntry);

        if (!monthEntry) return yearEntry.push(dataEntry);

        monthEntry.y += dataEntry.y;
      });

      if (property == "patients_per_month") meses.sort(this.compareByX);
      if (property == "patients_per_day") meses.sort(this.sortMonthsByDay);

      return [{ data: meses }];
    },
    sortMonthsByDay(a, b) {
      const dayA = parseInt(a.x.split(' ')[0]);
      const dayB = parseInt(b.x.split(' ')[0]);
      return dayA - dayB;
    },
    compareByX(a, b) {
      const dateA = new Date(a.x);
      const dateB = new Date(b.x);

      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    }
  },
};
</script>