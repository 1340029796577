<template>
  <b-card>
    <b-row align-h="between">
      <b-col cols="auto">
        <h1>Stadistics</h1>
      </b-col>
      <b-col cols="auto">
        <b-dropdown right text="Filters" variant="primary">
          <b-dropdown-form>
            <b-form-checkbox-group v-model="selected" v-for="({ title }, key) in stadistics" :key="key">
              <b-form-checkbox :value="title">{{ title }}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-dropdown-form>
        </b-dropdown>
      </b-col>
    </b-row>
    <vue-apex-charts v-if="series.length" :options="chartOptions" :series="series" type="bar" />
    <mixn ref="mixin" />
  </b-card>
</template>

<script>
import { BDropdownForm, BCard, BImg, BAvatar, BCardText, BDropdown, BFormCheckbox } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import mixn from "@/components/mixn.vue";

export default {
  components: {
    mixn,
    BDropdownForm,
    BCard,
    BAvatar,
    BImg,
    BCardText,
    VueApexCharts,
    BDropdown,
    BFormCheckbox
  },
  props: {
    stadistics: {
      type: Array,
      default: () => [],
      required: true
    },
  },
  watch: {
    stadistics() {
      this.filterData()
    },
    selected() {
      this.filterData(true)
    }
  },
  methods: {
    filterData(hasBeenSelected = false) {
      this.stadisticProps = this.stadistics
      this.series = [{
        data: this.stadistics
          .filter(({ title }) => !hasBeenSelected || this.selected.length === 0 || this.selected.includes(title))
          .map(({ value, title }) => ({ x: title, y: value }))
      }];
    },
  },
  data() {
    return {
      series: [],
      stadisticProps: [],
      selected: [],
      chartOptions: {
        colors: [],
        plotOptions: {
          bar: {
            borderRadius: 10,
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        tooltip: {
          y: {
            title: {
              formatter: (val, opt) => {
                const stadisticProps = this.stadisticProps
                const stadistic = stadisticProps.find((stadistic) => stadistic.title == opt.w.globals.labels[opt.dataPointIndex])?.description
                return stadistic + ':'
              }
            }
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#333'],
            fontSize: '16px', // Ajusta el tamaño de la letra aquí
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
          },
          offsetX: 0,
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        xaxis: {
          labels: {
            show: false
          },
          // categories: []
        },
      },
    }
  }
};
</script>