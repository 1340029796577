<template>
    <b-card text-variant="center" class="card card-congratulations">
        <!-- images -->
        <b-img :src="require('../assets/decore-left.png')" class="congratulations-img-left" />
        <b-img :src="require('../assets/decore-right.png')" class="congratulations-img-right" />
        <!--/ images -->

        <b-avatar variant="primary" size="70" class="shadow mb-2">
            <i class="flaticon2-user-outline-symbol text-white mx-3 cursor-pointer"></i>
        </b-avatar>
        <h1 class="mb-1 mt-50">
            Hi again {{ userData.name }},
        </h1>
        <b-card-text class="m-auto w-75">
            Have a good day
        </b-card-text>
    </b-card>
</template>

<script>
import {
    BCard, BImg, BAvatar, BCardText,
} from 'bootstrap-vue'

export default {
    components: {
        BCard,
        BAvatar,
        BImg,
        BCardText,
    },
    props: {
        userData: {
            type: Object,
            default: () => { },
        },
    },
}
</script>

<style lang="scss" scoped>
.card-congratulations {
    background: linear-gradient(118deg, rgba(#86d0f4, 1), rgba(#0011ff, 0.7));
    color: #fff;

    // image on left
    .congratulations-img-left {
        width: 200px;
        position: absolute;
        top: 0;
        left: 0;

        @media screen and (min-width: 544px) {
            width: 140px;
        }
    }

    // image on right
    .congratulations-img-right {
        width: 175px;
        position: absolute;
        top: 0;
        right: 0;

        @media screen and (min-width: 544px) {
            width: 140px;
        }
    }

    .avatar {
        margin-bottom: 2rem;
    }
}
</style>
