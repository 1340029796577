<template>
    <b-card>
        <h1>All procedures in {{ dias }} days</h1>
        <vue-apex-charts :options="chartOptions" :series="series" type="bar" />
        <mixn ref="mixin" />
    </b-card>
</template>

<script>
import mixn from "@/components/mixn.vue";
import {
    BContainer,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import axios from "@/core/services/api/admin/adminData";

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormInput,
        BButton,
        VueApexCharts,
        mixn
    },
    props: {
        start_date: {
            type: String,
            default: "",
            required: true
        },
        dataDashboard: {
            type: Object,
            default: () => { },
            required: true
        },
        end_date: {
            type: String,
            default: "",
            required: true
        }
    },
    data() {
        return {
            dias: 0,
            chartOptions: {
                dataLabels: {
                    enabled: false,
                },
            },
            series: [],
        };
    },
    watch: {
        dataDashboard() {
            this.getData()
        },
        start_date() {
            this.getData()
        },
        end_date() {
            this.getData()
        },
    },
    methods: {
        async getData() {
            try {
                const userData = JSON.parse(localStorage.getItem("userData"));
                const { id } = userData;
                const { data } = await axios.getAdminDataPaginated(1, {
                    user_id: id,
                    end_date: this.end_date,
                    start_date: this.start_date,
                });

                this.dias = this.$refs.mixin.dayDiferences(this.start_date, this.end_date);
                const arreglo = Object.entries(data.DETAIL_OFFICE_VISIT);

                this.series = arreglo.map((visit, indx) => ({
                    name: visit[0],
                    data: [{ x: 'Visits', y: visit[1] }],
                    color: this.$refs.mixin.listaColores(indx),
                }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    },
};
</script>

<style scoped lang="scss"></style>
