<template>
  <!-- Este es el promedio de la clinica   -->
  <b-card class="tarjeta">
    <div>
      <img src="../assets/Winners-pana.svg" width="135%" style="margin-left: -50px" />
      <h1 class="mb-1 mt-50">Clinic average! ${{ clinicAvg.toFixed(2) }}</h1>
    </div>
  </b-card>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
    dataDashboard: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      clinicAvg: 0,
    }
  },
  watch: {
    dataDashboard() {
      this.clinicAvg = this.dataDashboard.average?.net_income_days ?? 0
    },
  },
}
</script>

<style lang="scss">
.tarjeta {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
