<template>
  <b-card>
    <h1>Type visits of {{ getCurrentMonth() }} - {{ total }}</h1>
    <vue-apex-charts :options="chartOptions" :series="series" type="pie" />
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { BCard } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    userData: {
      type: Object,
      default: () => ({}),
    },
    dataDashboard: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 400,
          width: '100%',
        },
        tooltip: {
          theme: 'custom',
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#3C231D'],
          },
          dropShadow: {
            enabled: false,
          },
        },
        labels: [],
        colors: [
          '#fbd5cc',
          '#ffc699',
          '#cadbbb',
          '#91b3a5',
          '#a6c6dd',
          '#ccdafd',
          '#c5dff0',
          '#f8e6b4',
        ],
      },
      series: [],
      total: 0,
      isSmallScreen: window.innerWidth <= 768,
    };
  },
  watch: {
    dataDashboard: {
      immediate: true,
      handler() {
        this.updateChartData();
      },
    },
  },
  methods: {
    updateChartData() {
      // Inicializar el objeto totalPatients
      let totalPatients;

      if (this.isSmallScreen) {
        totalPatients = {
          'F/U': 0,
          'N/C': 0,
          'N/P': 0,
          'P/I': 0,
          'P/P': 0,
          'R/S': 0,
          'Vacc': 0,
        };
      } else {
        totalPatients = {
          'Follow Up': 0,
          'New Consultation': 0,
          'New patient': 0,
          'Pending Injection': 0,
          'Pending Procedure': 0,
          'Result': 0,
          'COVID-19 Vaccination': 0,
        };
      }

      if (this.dataDashboard.year_data) {
        this.dataDashboard.year_data.forEach(facilities => {
          facilities.facilities.forEach(res => {
            if (this.isSmallScreen) {
              totalPatients['F/U'] += res['F/U'] || 0;
              totalPatients['N/C'] += res['N/C'] || 0;
              totalPatients['N/P'] += res['N/P'] || 0;
              totalPatients['P/I'] += res['P/I'] || 0;
              totalPatients['P/P'] += res['P/P'] || 0;
              totalPatients['R/S'] += res['R/S'] || 0;
              totalPatients['Vacc'] += res['V/C'] || 0;
            } else {
              totalPatients['Follow Up'] += res['F/U'] || 0;
              totalPatients['New Consultation'] += res['N/C'] || 0;
              totalPatients['New patient'] += res['N/P'] || 0;
              totalPatients['Pending Injection'] += res['P/I'] || 0;
              totalPatients['Pending Procedure'] += res['P/P'] || 0;
              totalPatients['Result'] += res['R/S'] || 0;
              totalPatients['COVID-19 Vaccination'] += res['V/C'] || 0;
            }
          });
        });

        this.total = Object.values(totalPatients).reduce((acc, value) => acc + value, 0);

        // Actualiza las series y etiquetas del gráfico
        this.series = Object.values(totalPatients);
        this.chartOptions.labels = Object.keys(totalPatients).map(
          (key, index) => `${key}: ${this.series[index]}`
        );

        // Forzar la reactividad para actualizar las opciones del gráfico
        this.$nextTick(() => {
          this.chartOptions = { ...this.chartOptions };
        });
      }
    },
    getLabels() {
      return this.isSmallScreen
        ? ['F/U: 0', 'N/C: 0', 'N/P: 0', 'P/I: 0', 'P/P: 0', 'R/S: 0', 'Vacc: 0']
        : [
            'Follow Up: 0',
            'New Consultation: 0',
            'New patient: 0',
            'Pending Injection: 0',
            'Pending Procedure: 0',
            'Result: 0',
            'COVID-19 Vaccination: 0',
          ];
    },
    getCurrentMonth() {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      return months[new Date().getMonth()];
    },
  },
  mounted() {
    this.updateChartData();
  },
};
</script>

