<template>
  <b-card>
    <b-row>
      <b-col>
        <label>{{ $t("Form.Start Date") }}</label>
        <flat-pickr v-model="start" :config="config" class="form-control" type="date" />
      </b-col>
      <b-col>
        <label>{{ $t("Form.End Date") }}</label>
        <flat-pickr v-model="end" :config="config" class="form-control" type="date" />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="10" v-if="advanced">
        <label>Facilities</label>
        <treeselect v-model="facility" class="rounded" style="background: #fff" :options="facilityOptions"
          :multiple="true" :clearable="true" :disabled="facilityOptions.length === 1" :normalizer="normalizer"
          :value-format="facilityOptions.length === 1 ? 'object' : 'id'" />
      </b-col>
      <b-col cols="2" align-self="end">
        <b-button class="mt-3" variant="primary" @click="$emit('buscar', { start, end, facilities: facility })">Ok</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow, BButton } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    flatPickr,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      default: () => { },
    },
    advanced: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  data() {
    return {
      start: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`,
      end: `${new Date().getFullYear()}-${new Date().getMonth() + 1
        }-${new Date().getDate()}`,
      config: {
        dateFormat: "Y-m-d",
        allowInput: true,
        minDate: this.advanced ? false : `${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`,
        maxDate: this.advanced ? false : `${new Date().getFullYear()}-${new Date().getMonth() + 2}-1`
      },
      facilityOptions: [],
      facility: [],
      setTimeoutBuscador: "",
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  mounted() {
    // this.facility = [JSON.parse(atob(localStorage.getItem("Facility")))];
    this.facilityOptions = JSON.parse(
      atob(localStorage.getItem("setFacilityOptions"))
    );
  },
};
</script>

<style lang="scss" scoped>
.button {
  position: absolute;
  left: 90%;
  top: 5%;
}
</style>
